import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from '../../component/slider2';
import Tanitim from '../../component/tanitim';
import Topbar from '../../component/topbar';
import Faaliyet from '../../component/faaliyet';
import Footer from '../../component/footer';
import WhyMobilassistance from '../../component/why';
import Online from '../../component/onlineislemler';
import IkameAracHizmeti from '../../component/ikamearac';
import React, { useEffect, useState } from "react";

const MainPage = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    AOS.init();
    return (
        <div className="App"> 
            <Topbar />
            {width > 800 ? 
            <Slider /> 
            : 
            <div style={{ height: 250 }} ></div>} 
            <Online />
            <Tanitim /> 
            {width > 900 ? 
            <IkameAracHizmeti />
            : 
            <div style={{ height: 5 }} ></div>}  
             {width > 900 ? 
            <Faaliyet />
            : 
            <div style={{ height: 5 }} ></div>}   
            <WhyMobilassistance />
            <Footer />
        </div>
    );
};

export default MainPage; 