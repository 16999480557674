

import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { useState } from "react";
import InputMask from "react-input-mask";
import { Formik } from "formik";
import * as Yup from 'yup';

import Kvkk from "../../application/kvkk";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';


import iletisim from '../../../assets/img/iletisim/iletisimform.gif'

const validationSchema = Yup.object({
    formdsNameSurname: Yup.string().required("Zorunlu alan"),
    formdsEmail: Yup.string().email('Geçersiz e-mail adresi').required('Zorunlu alan'),
    formdsPhone: Yup.string().required('Telefon Numarası Zorunludur').matches(/^(05(\d{9}))$/  ,"05xx Formatında doldurun"),
    formdsMessage: Yup.string().required('Mesaj alanı zorunludur'), 
 });


const IletisimFormSend = () => {
    const [basvuruekrani, setBasvuruEkrani] = useState(true)
    const [sonuc, setSonuc] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingError, setLoadingError] = useState(false)
    const [iletisimmodalShow, setIletisimModalShow] = useState(false); 

    const Kayit = (values) => {
        setLoading(true);
        axios.post('https://app.mobilassistance.com/website-service/api/Contact/new-message', {
            dsNameSurname: values.formdsNameSurname,
            dsEmail: values.formdsEmail,
            dsPhone: values.formdsPhone,
            dsMessage: values.formdsMessage
        })
            .then(response => {
                setBasvuruEkrani(false);
                setLoading(false);
                setSonuc(true)
            })
            .catch(error => {
                setLoading(false);
                setBasvuruEkrani(false)
                setLoadingError(true)
            });
    }

    function ModalKvkk(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Kişisel Verilerin Korunması Kanunu (KVKK) Bilgilendirme Yazısı
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Kvkk />
                </Modal.Body>
                <Modal.Footer>
                    <Button className='c-button' onClick={props.onHide}>Okudum anladım</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    function BasariliSonuc() {
        return (
            <>
                <Alert variant="success">
                    <Alert.Heading>Mesajınız başarılı şekilde tarafımıza ulaştı</Alert.Heading>
                    <p>
                        Mesajınız merkezimiz taradından işlem sırasına alınmıştır. En kısa süre içerisinde değerlendirilip tarafınıza dönüş
                        sağlanacaktır.
                    </p>
                    <hr />
                    <p className="mb-0">
                        İyi Günler dileriz
                    </p>
                </Alert>
            </>
        )
    }

    function HataliSonuc() {
        return (
            <>
                <Alert variant="danger">
                    <Alert.Heading>Mesaj gönderiminde bir hata oluştu</Alert.Heading>
                    <p>
                        Mesaj gönderimesnasında bir hata oluştu. Lütfen formu tekrardan gözden geçirin
                    </p>
                    <hr />
                    <p className="mb-0">
                        <button className="c-button" onClick={() => { setBasvuruEkrani(true); setLoadingError(false) }}>Forma Geri Dön</button>
                    </p>
                </Alert>
            </>
        )
    }
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="o-container__wrapper">
                    <div className="c-contact">
                        <div>
                            <div className="c-tabs__content">
                                <div className="c-section-title">
                                    <div className="c-section-title__sub-title" >İLETİŞİM
                                    </div>
                                </div>
                                <br></br><br></br>
                                <div className="">
                                    <div className="container">
                                        <div className="row no-gutters" >
                                            <div className="col-lg-6 col-md-6 content-item" >
                                                <div>
                                                    <img src={iletisim} alt='Mobil Assistance İletişim' width={'100%'}></img>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 content-item">
                                                {basvuruekrani && (
                                                    <>
                                                        <div className="o-container__wrapper contact-ss" style={{ width: "100%%", margin: "auto", }}>
                                                            <div className="page-content">
                                                                <Formik
                                                                    initialValues={{
                                                                        formdsNameSurname: '',
                                                                        formdsEmail: '',
                                                                        formdsPhone: '',
                                                                        formdsMessage: '', 
                                                                    }}
                                                                    validationSchema={validationSchema}
                                                                    onSubmit={(values, actions) => {
                                                                        setTimeout(() => {
                                                                            // alert(JSON.stringify(values, null, 2));
                                                                            Kayit(values);
                                                                            actions.setSubmitting(false);
                                                                        }, 1000);
                                                                    }}
                                                                >
                                                                    {props => (
                                                                        <form id="contact-form" onSubmit={props.handleSubmit} >
                                                                            <input type="hidden" id="js-token" />
                                                                            <div className="form-column__row">
                                                                                <div className="form-column">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="nameSurname">Adınız Soyadınız</label>
                                                                                        <input type="text" id="nameSurname" maxlengt="200"
                                                                                            name="nameSurname" placeholder="Adınız" value={props.values.formdsNameSurname} onChange={props.handleChange('formdsNameSurname')} />
                                                                                        {props.errors.formdsNameSurname && props.errors.formdsNameSurname}
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="email">E-Posta Adresiniz</label>
                                                                                        <input type="email" id="email" name="email"
                                                                                            placeholder="E-Posta Adresiniz" value={props.values.formdsEmail} onChange={props.handleChange('formdsEmail')} />
                                                                                        {props.errors.formdsEmail && props.errors.formdsEmail}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-column">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="phone">Telefon Numaranız</label>
                                                                                        {/* ref={inputRef_telefon}   */}
                                                                                        <InputMask name='phone' placeholder='Telefon'
                                                                                            className="mobile-phone"
                                                                                            mask="05999999999" maskChar={null} value={props.values.formdsPhone} onChange={props.handleChange('formdsPhone')} />
                                                                                        {props.errors.formdsPhone && props.errors.formdsPhone}
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="message">Mesajınız</label>
                                                                                        <textarea name="message" id="message"
                                                                                            placeholder="Mesajınız" value={props.values.formdsMessage} onChange={props.handleChange('formdsMessage')}></textarea>
                                                                                        {props.errors.formdsMessage && props.errors.formdsMessage}
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-center flex-column mb-4">
                                                                                        <div className="w-100 justify-content-center d-flex">
                                                                                            <div className="custom-control custom-checkbox">
                                                                                                <input className="custom-control-input"
                                                                                                    type="checkbox" id="kvkkcheck" name="kvkkcheck"  value={props.values.kvkkcheck} /> 
                                                                                                <label className="custom-control-label"
                                                                                                    htmlFor="kvkk"></label>
                                                                                                <p className="d-inline" id="kvkk-text">
                                                                                                    {iletisimmodalShow && (
                                                                                                        <ModalKvkk
                                                                                                            show={iletisimmodalShow}
                                                                                                            onHide={() => setIletisimModalShow(false)}
                                                                                                        />
                                                                                                    )}
                                                                                                    <a href={() => false}
                                                                                                        onClick={() => setIletisimModalShow(true)}><u>Kişisel
                                                                                                            verilerin korunması</u> izin metni</a>'ni
                                                                                                    okudum ve onaylıyorum.
                                                                                                </p>
                                                                                                <br></br>
                                                                                                {props.errors.kvkkcheck && props.errors.kvkkcheck}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-actions">
                                                                                <button className="c-button" type="submit"
                                                                                //  disabled={isSubmitting} 
                                                                                >GÖNDER
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {sonuc && (
                                                    <>
                                                        <BasariliSonuc />
                                                    </>
                                                )}
                                                {loading && (
                                                    <>Kayıt İşlemi Yapılıyor... Bekleyin</>
                                                )}
                                                {loadingError && (
                                                    <>
                                                        <HataliSonuc />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default IletisimFormSend;