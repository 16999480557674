import { Sling as Hamburger } from 'hamburger-react'
import { useEffect, useRef, useState } from "react";
import logo from '../assets/img/MobilAssistance.svg' 

import logo2 from '../assets/img/MobilAssistance.png'

function Topbar() {
    const [isOpen, setOpen] = useState(false);
    const ref = useRef(null);
    const [kurumsalisopen, setiskurumsalOpen] = useState(false);
    const ToggleKurumsal = () => {
        kurumsalisopen === true ? setiskurumsalOpen(false) : setiskurumsalOpen(true);
        hizmetlerisopen === true ? setishizmetlerOpen(false) : setishizmetlerOpen(false);
        onlineisopen === true ? setisonlineOpen(false) : setisonlineOpen(false);
    }
    const [hizmetlerisopen, setishizmetlerOpen] = useState(false);
    const ToggleHizmetler = () => {
        kurumsalisopen === true ? setiskurumsalOpen(false) : setiskurumsalOpen(false);
        hizmetlerisopen === true ? setishizmetlerOpen(false) : setishizmetlerOpen(true);
        onlineisopen === true ? setisonlineOpen(false) : setisonlineOpen(false);
    }

    const [onlineisopen, setisonlineOpen] = useState(false);
    const ToggleOnline = () => {
        onlineisopen === true ? setisonlineOpen(false) : setisonlineOpen(true);
        kurumsalisopen === true ? setiskurumsalOpen(false) : setiskurumsalOpen(false);
        hizmetlerisopen === true ? setishizmetlerOpen(false) : setishizmetlerOpen(false);
    }

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return (
        <>
            <header className="o-header" role="presentation" >
                {width > 850 ?
                    <div className="o-header__top">
                        <div className="o-header__logo">
                            <div className="c-animated-logo" >
                                <div className="main-logo-container">
                                    <div className="signal-container">
                                        <div className="signal-element"></div>
                                        <div className="signal-element-2"></div>
                                    </div>
                                    <div className="bottom-container">
                                        <div className="logo-top-wrapper">
                                            <a alt="logo" href="/">
                                                <img className='LogoWidth' alt='Mobil Assistance' src={logo}></img></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="o-header__nav">
                            <ul className="c-nav">
                                <li className="c-nav__item">
                                    <div className="c-nav__item--menu-trigger" >
                                        <div className="o-nav-icon-bayi">
                                            <div >
                                                <a className="c-special-button" style={{ width: 120, backgroundColor: 'white', color: 'black', }} target='_blank' rel='noreferrer' href="https://bayi.mobilassistance.com/Bayi_/Giris">BAYİ GİRİŞİ </a>
                                            </div>
                                        </div>
                                        <div className="o-nav-icon">
                                            MENÜ <Hamburger toggled={isOpen} size={40} toggle={setOpen} />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :
                    <>
                        <div className='mobil'>
                            <div style={{ height: 100 }} ></div>
                            <a alt="logo" href="/"> <img alt='Mobil Assistance' src={logo2}></img></a>
                        </div>
                        <div className="o-header__top">
                            <div className="o-header__nav">
                                <ul className="c-nav">
                                    <li className="c-nav__item">
                                        <div className="c-nav__item--menu-trigger" >
                                            <div className="o-nav-icon-bayi">
                                                <div >
                                                    <a className="c-special-button" style={{ width: 120, backgroundColor: 'white', color: 'black', }} target='_blank' rel='noreferrer' href="https://bayi.mobilassistance.com/Bayi_/Giris">BAYİ GİRİŞİ </a>
                                                </div>
                                            </div>
                                            <div className="o-nav-icon">
                                                MENÜ <Hamburger toggled={isOpen} size={40} toggle={setOpen} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                }
            </header>
            <div ref={ref} className='c-nav__item--menu-trigger' >
                {isOpen && (
                    <>
                        <div className="c-main-nav__container" style={{ display: 'block' }}>
                            <div className="o-container">
                                <div className="c-main-nav__wrapper">
                                    <ul className="c-main-nav">
                                        <li className="c-main-nav__item submenu">
                                            <a className="parent-link" href={() => false} onClick={ToggleKurumsal} >Kurumsal</a>
                                            {kurumsalisopen && (
                                                <ul className="sub-menu" style={{ display: 'block' }}>
                                                    <li>
                                                        <a href="/tr/kurumsal/mobil-assistance-hakkinda">Amaç</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/kurumsal/kurumsal-hedefler">Kurumsal Hedeflerimiz</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/kurumsal/tr/kurumsal/kalite-politikamiz">Kalite Politikamız</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/kurumsal/kalite-belgelerimiz">Belgelerimiz</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/kurumsal/tarihcemiz">Tarihçemiz</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/kurumsal/etik-kurallarimiz">Etik Kurallarımız</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/kurumsal/kurumsal-kimlik">Kurumsal Kimlik</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/kurumsal/tanitim-filmi">Tanıtım Filmi</a>
                                                    </li>
                                                </ul>
                                            )}
                                        </li>
                                        <li className="c-main-nav__item c-main-nav__item--has-submenu">
                                            <a className="parent-link" href={() => false} onClick={ToggleHizmetler} >Hizmetlerimiz</a>
                                            {hizmetlerisopen && (
                                                <ul className="sub-menu" style={{ display: 'block' }}>
                                                    <li>
                                                        <a href="/tr/hizmetlerimiz/yol-yardim-kurtarma-hizmeti">Yol Yardım Hizmeti</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/hizmetlerimiz/ikame-arac-hizmeti">İkame Araç Hizmeti</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/hizmetlerimiz/cagri-merkezi-hizmeti">Çağrı Merkezi Hizmeti</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/hizmetlerimiz/kisiye-ozel-hizmet-paketleri">Kişiye Özel Yol Yardım Paketleri</a>
                                                    </li>
                                                </ul>
                                            )}
                                        </li>

                                        <li className="c-main-nav__item c-main-nav__item--has-submenu">
                                            <a className="parent-link" href={() => false} onClick={ToggleOnline} >Online İşlemler</a>
                                            {onlineisopen && (
                                                <ul className="sub-menu" style={{ display: 'block' }}>
                                                    <li>
                                                        <a href="tr/insan-kaynaklari/prensiplerimiz">Bayi Girişi</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/online-islemler/sozlesme-ara">Sözleşme Ara</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/online-islemler/bayilik-basvurusu">Bayi Başvurusu</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/online-islemler/tedarikci-basvurusu">Tedarikçi Başvurusu</a>
                                                    </li>
                                                    <li>
                                                        <a href="genc-yetenek-programi">Tedarikçi Ağımız</a>
                                                    </li>
                                                    <li>
                                                        <a href="/tr/online-islemler/bayi-agimiz">Bayi Ağımız</a>
                                                    </li>
                                                </ul>
                                            )}
                                        </li>

                                        <li className="c-main-nav__item c-main-nav__item--has-submenu">
                                            <a className="parent-link" href="/tr/mobil-assistance/insan-kaynaklari">İnsan Kaynakları</a>
                                        </li>
                                        <li className="c-main-nav__item c-main-nav__item--has-submenu">
                                            <a className="parent-link" href="/tr/mobil-assistance/iletisim">İletişim</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
export default Topbar;